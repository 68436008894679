import React, { useEffect } from "react"
import {
    Layout,
    Loader,
} from "@components"
import { useFAQs } from "../common/hooks"
import { getFAQs } from "@services"
import { FAQ } from "../components"
import scholarsImage from "../images/people.jpg"

const CommunityScholarFAQ = () => {
    const {
        FAQStates,
        handleInitialState
    } = useFAQs()

    useEffect(() => {
        const loadResources = async () => {
            const resources = await getFAQs()
            handleInitialState(resources)
        }
        loadResources()
    }, [])

    return (
        <Layout>
            <div className="post-container p-8">
                <div className="blog-post">
                    <div className="bg-cover bg-center -m-8 mb-6" style={{ backgroundImage: `url(${scholarsImage})` }}>
                        <div className="bg-black bg-opacity-50 h-full w-full flex py-24 px-8">
                            <h1 className="text-white">Northwell Community Scholars - FAQ</h1>
                        </div>
                    </div>
                    <div
                        className="blog-post-content"
                    />
                    <p>Welcome to the FAQ! If you have a question that don't see on here, please ask it on our discussion board&#160;
                        <a href="https://forms.office.com/Pages/ResponsePage.aspx?id=hW54f2ErvkunlnHpHl5-ODBqqf9IjapCrmqwYMzmVMRUNEQ3VzdGRUhVN0pZM1JWSjNCTk9CNEc0SyQlQCN0PWcu" target="_blank">
                            here
                        </a>.
                    </p>
                </div>
                {FAQStates.isLoading && <Loader label="loading..." />}
                {!FAQStates.isLoading && <FAQ faqs={FAQStates.FAQs}></FAQ>}
            </div>
        </Layout>
    )
}

export default CommunityScholarFAQ